<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Property</th>
          <th class="text-left">Value</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="key in entity_keys" :key="key.name">
          <td>{{ key.name }}</td>
          <td>{{ key.value }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  export default {
    name: 'EntityProperties',
    props: {
      entity: {
        type: Object,
        required: true
      },
      exclude: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
    }),
    computed:{
      entity_keys() {
        let prop_list = []
        var obj = this.entity
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            if(!this.exclude.includes(prop)) {
              prop_list.push({name: prop, value: obj[prop]})
            }
          }
        }
        prop_list.sort(function (a,b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          let comparison = 0;
          if (nameA > nameB) {
            comparison = 1;
          } else if (nameA < nameB) {
            comparison = -1;
          }
          return comparison;

        })
        return prop_list

      }
    }
    ,
    mounted() {
      console.log("Entity Properties")
      console.log(this.entity)
    },

  }
</script>
