<template>
  <div class="user">
    <h1>User Account</h1>
    <v-card>
      <v-card-title>
        {{this.user.full_name}}
      </v-card-title>
      <v-list>
        <v-list-item>
          Email: {{this.user.email}}
        </v-list-item>
        <v-list-item>
          Is Superuser:  {{this.user.is_superuser}}
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col cols="1">
              API Token:
            </v-col>

            <v-col cols="6">
              <v-textarea :value="this.api_token">
              </v-textarea>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn @click="generateApiKey">Generate API Key</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  // @ is an alias to /src
  import {mapActions} from "vuex";
  import axios from "axios";

  export default {
    name: 'userAccount',
    data: () => ({
      user: {},
      api_token: ""
    }),
    methods: {
      getUser(){
        let that = this
        axios({
          method: 'get',
          url: `${this.$store.state.host}/users/me`,
        })
                .then(function (response) {
                  //handle success
                  if(response.status === 200){
                    that.user = response.data
                  }
                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });

      },
      generateApiKey(){
        let that = this
        axios({
          method: 'get',
          url: `${this.$store.state.host}/api/v1.0/user/api_key`,
        })
                .then(function (response) {
                  //handle success
                  if(response.status === 200){
                    that.api_token = response.data.api_token
                  }
                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });
      },
      ...mapActions([
        'loadTreeView'
      ])
    },
    watch: {
      $route(to, from) {
        this.getUser()
      }
    },
    mounted(){
      this.getUser()
    },
  }
</script>
