<template>
  <div id="feeder-amps-chart" style="min-width: 1200px; min-height: 700px">
  </div>
</template>

<script>
  import axios from "axios";
  import Plotly from "plotly.js-dist"

  export default {
    name: 'FeederAmpsGraph',
    props: {
      entityId: {
        type: String,
        required: true
      },
      entityName: {
        type: String,
        required: true
      }
    },
    data: () => ({
      amps_a_phase: {},
      amps_b_phase: {},
      amps_c_phase: {},
      ampsChart: {amps_meta: {name: "Feeder Amps"}}
    }),
    computed: {},
    methods: {
      getFeederAmps(){
        let that = this;

        axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/metric/${this.entityId}/amps_a_phase/survalent_feeder_amps`)
                .then(function (response) {
                  that.amps_a_phase = response.data.metric
                  that.createChart()
                })
                .catch(function (error) {
                  console.log(error);
                });
        axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/metric/${this.entityId}/amps_b_phase/survalent_feeder_amps`)
            .then(function (response) {
              that.amps_b_phase = response.data.metric
              that.createChart()
            })
            .catch(function (error) {
              console.log(error);
            });
        axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/metric/${this.entityId}/amps_c_phase/survalent_feeder_amps`)
            .then(function (response) {
              that.amps_c_phase = response.data.metric
              that.createChart()
            })
            .catch(function (error) {
              console.log(error);
            });

      },
      createChart() {
        this.ampsChart.amps_meta.name = this.entityName;
        console.log("Creating Feeder amps")
        const ctx = document.getElementById("feeder-amps-chart");
        let trace_a = {
          type: "scatter",
          mode: "lines",
          name: 'Amps A',
          x: this.amps_a_phase.x,
          y: this.amps_a_phase.y,
          line: {color: '#cf1717'}};
        let trace_b = {
          type: "scatter",
          mode: "lines",
          name: 'Amps B',
          x: this.amps_b_phase.x,
          y: this.amps_b_phase.y,
          line: {color: '#1ebe4c'}};
        let trace_c = {
          type: "scatter",
          mode: "lines",
          name: 'Amps C',
          x: this.amps_c_phase.x,
          y: this.amps_c_phase.y,
          line: {color: '#131cd0'}};
        let layout = {
          title: this.ampsChart.amps_meta.name,
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero'
          }
        };
        let data = [trace_a, trace_b, trace_c];
        this.metricChart = Plotly.newPlot(ctx,
                data,
                layout);
      },
    },
    mounted() {
      this.getFeederAmps();
      console.log("Feeder Amps")
    },

  }
</script>
