<template>
  <div>
    <v-alert type="error" v-if="dataNotFound">
      No regulator data found for {{picker}}
    </v-alert>
    <v-row>
      <v-col cols="12" sm="6" md="4" style="z-index: 9000">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="picker"
                label="Daily Summary Date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="picker" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="dailyRegulatorReportLoading">
          <v-card-title>
            Daily Regulator Report
          </v-card-title>
          <daily-regulator-report :report="dailyRegulatorReport"></daily-regulator-report>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card :loading="dailyLDPReportLoading">
          <v-card-title>
            Daily LDP Report
          </v-card-title>
          <daily-l-d-p-report :report="dailyLDPReport"></daily-l-d-p-report>
        </v-card>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import axios from 'axios'
import DailyRegulatorReport from "@/components/DailyRegulatorReport";
import DailyLDPReport from "@/components/DailyLDPReport";

export default {
  name: 'DailyReport',
  data: () => ({
    dailyRegulatorReport: [],
    dailyLDPReport: [],
    dailyRegulatorReportLoading: false,
    dailyLDPReportLoading: false,
    picker: new Date().toISOString().substr(0, 10),
    menu: false,
    dataNotFound: false,
  }),
  props: {
    report_date: {
      type: String,
      required: false
    }
  },
  methods: {
    getDailyReports(){
      let that = this
      that.dailyRegulatorReportLoading = true;
      that.dailyLDPReportLoading = true;
      axios.get(`${this.$store.state.host}/api/v1.0/pyreports/daily/` + this.picker)
          .then(function (response) {
            that.dailyRegulatorReport = response.data.Regulator.report
            that.dailyLDPReport = response.data.LDP.report
            that.dailyRegulatorReportLoading = false;
            that.dailyLDPReportLoading = false;
            console.log(response.data)
          })
          .catch(function (error) {
            console.log(error);
            that.dataNotFound = true;
            that.dailyRegulatorReportLoading = false;
            that.dailyLDPReportLoading = false;
          });
    },
  },
  mounted() {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate())
    this.picker = today.toISOString().substr(0, 10)
    if(this.report_date) {
      this.picker = this.report_date;
    }

    this.getDailyReports();

  },
  watch: {
    $route(to, from) {
      this.getDailyReports();
      this.picker = this.report_date
    },
    picker(val){
      if(this.report_date !== val) {
        this.$router.push({path: `/pyreports/daily/${val}`})
        this.dataNotFound = false;
      }
    }
  },
  components: {
    DailyRegulatorReport: DailyRegulatorReport,
    DailyLDPReport: DailyLDPReport
  }
}
</script>
