<template>
  <div id="watts-vars-pf-chart" style="min-width: 1200px; min-height: 700px">
  </div>
</template>

<script>
  import axios from "axios";
  import Plotly from "plotly.js-dist"

  export default {
    name: 'WattsVarsPfGraph',
    props: {
      entityId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      wattsVarsPfChart: {metric_meta: {name: "Title"}},
      wattsVarsPf: {}
    }),
    computed: {},
    watch: {
      entityId: function() {
        console.log(this.entityId);
        this.getWattsVarsPf();
      }
    },
    methods: {
      createChart() {
        console.log("Creating Chart")
        const ctx = document.getElementById("watts-vars-pf-chart");
        let wattsTrace = {
          type: "scatter",
          mode: "lines",
          name: 'Watts 3-Phase',
          x: this.wattsVarsPf.Watts_3Ph.x,
          y: this.wattsVarsPf.Watts_3Ph.y,
          line: {color: '#209191'}
        };
        let varsTrace = {
          type: "scatter",
          mode: "lines",
          name: 'Vars 3-Phase',
          x: this.wattsVarsPf.Vars_3Ph.x,
          y: this.wattsVarsPf.Vars_3Ph.y,
          line: {color: '#ab5d29'}
        };
        let pfTrace = {
          type: "scatter",
          mode: "lines",
          name: 'PF 3-Phase',
          x: this.wattsVarsPf.PF_3Ph.x,
          y: this.wattsVarsPf.PF_3Ph.y,
          yaxis: "y2",
          line: {color: '#2cc145'}
        };
        let layout = {
          title: "title",
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero',
            title: "Watts/Vers 3-Phase"
          },
          yaxis2: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero',
            side: 'right',
            overlaying: 'y',
            title: "PF 3-Phase"
          }
        };
        let data = [wattsTrace, varsTrace, pfTrace];
        this.wattsVarsPfChart = Plotly.newPlot(ctx,
            data,
            layout);
      },
      getWattsVarsPf() {
        let that = this;
        axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/watts_vars_pf/${this.entityId}`)
            .then(function (response) {
              that.wattsVarsPf = response.data.watts_vars_pf
              that.createChart()
            })
            .catch(function (error) {
              console.log(error);
            });
      },
    },
    mounted() {
      console.log("Watts Vars Pf Graph")
      console.log("Entity ID: "+ this.entityId)
      this.getWattsVarsPf()
    },
  }

</script>
