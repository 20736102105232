<template>
  <div>
    <meter-voltage-graph v-if="voltage_data_loaded" :meter_number="meter_number" :voltage_records="voltage_records"></meter-voltage-graph>
    <meter-blink-graph v-if="blink_data_loaded" :meter_number="meter_number" :blink_records.sync="blink_records"></meter-blink-graph>
    <meter-billing-graph v-if="billing_data_loaded" :meter_number="meter_number" :billing_diff_records="billing_diff_records" :billing_records="billing_records"></meter-billing-graph>
    <meter-meta-edit v-if="meta_data_loaded" :meter_number="meter_number" :meter-meta="meter_meta"></meter-meta-edit>

  </div>
</template>

<script>
import axios from "axios";
import MeterVoltageGraph from "../components/MeterVoltageGraph";
import MeterBlinkGraph from "../components/MeterBlinkGraph";
import MeterBillingGraph from "@/components/MeterBillingGraph";
import MeterMetaEdit from "@/components/MeterMetaEdit";


export default {
  name: 'MeterDetail',
  data: () => ({
    voltage_data_loaded: false,
    blink_data_loaded: false,
    billing_data_loaded: false,
    meta_data_loaded: false,

    meter_meta: {},
    blink_records: [],
    voltage_records: [],
    billing_records: [],
    billing_diff_records: []

  }),
  props: {
    meter_number: {
      type: String,
      required: true
    },
  },
  computed:{
  },
  methods:{
    getMeters(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/meter/${this.meter_number}`)
          .then(function (response) {
            if(response.data.hasOwnProperty('blink_records'))
            {
              that.blink_records = response.data.blink_records;
              that.blink_data_loaded = true;
            }
            if(response.data.hasOwnProperty('billing_records'))
            {
              that.billing_records = response.data.billing_records;
              that.billing_diff_records = response.data.billing_diff_records;
              that.billing_data_loaded = true;
            }
            if(response.data.hasOwnProperty('records'))
            {
              that.voltage_records = response.data.records;
              that.voltage_data_loaded = true;
            }
            if(response.data.hasOwnProperty('meter_meta'))
            {
              that.meter_meta = response.data.meter_meta;
              that.meta_data_loaded = true;
            }

          })
          .catch(function (error) {
            console.log(error);
          });
    },
    createChart() {
    },
  }
  ,
  mounted() {
    console.log("Meter Detail")
    this.getMeters();
  },
  components: {
    MeterVoltageGraph: MeterVoltageGraph,
    MeterBlinkGraph: MeterBlinkGraph,
    MeterBillingGraph: MeterBillingGraph,
    MeterMetaEdit: MeterMetaEdit
  }

}
</script>
