<template>
  <div>
    <v-row>
      <v-card class="mb-4">
        <v-card-title>Meter Properties</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <td>A-Phase Nominal Voltage</td>
                <td>{{meterMeta.a_phase_nominal}} V</td>
              </tr>
              <tr>
                <td>B-Phase Nominal Voltage</td>
                <td>{{meterMeta.b_phase_nominal}} V</td>
              </tr>
              <tr>
                <td>C-Phase Nominal Voltage</td>
                <td>{{meterMeta.c_phase_nominal}} V</td>
              </tr>
              <tr>
                <td>Transformer Type</td>
                <td>{{meterMeta.transformer_type}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>

          <template>
            <v-row justify="start">
              <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ml-4"
                  >
                    Edit
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Meter Properties</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <v-text-field
                              label="A-Phase Nominal Voltage*"
                              required
                              v-model="edit_a_phase_nominal"

                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                          <v-text-field
                              label="B-Phase Nominal Voltage*"
                              required
                              v-model="edit_b_phase_nominal"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                          <v-text-field
                              label="C-Phase Nominal Voltage*"
                              required
                              v-model="edit_c_phase_nominal"
                          ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                          <v-select
                              :items="['corner grounded', 'center grounded', 'measured wye', '54+']"
                              label="Transformer Type*"
                              required
                              v-model="edit_transformer_type"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="updateMeterMeta"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>

        </v-card-actions>
      </v-card>
    </v-row>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'MeterMetaEdit',
  data: () => ({
    dialog: false,
    edit_a_phase_nominal: 0,
    edit_b_phase_nominal: 0,
    edit_c_phase_nominal: 0,
    edit_transformer_type: ""

  }),
  props: {
    meter_number: {
      type: String,
      required: true
    },
    meterMeta: {
      type: Object,
      required: true
    },

  },
  computed:{
  },
  methods:{
    updateMeterMeta(){
      let that = this;
      let data = {'meter_meta': {'a_phase_nominal': that.edit_a_phase_nominal,
          'b_phase_nominal': that.edit_b_phase_nominal,
          'c_phase_nominal': that.edit_c_phase_nominal,
          'transformer_type': that.edit_transformer_type}}
      axios.post(`${this.$store.state.host}/api/v1.0/meter/${this.meter_number}`, data).then(function (response) {
        console.log(response)
        if(response.status === 200 && response.data.status ==='success'){
          that.dialog = false;
          that.meterMeta.a_phase_nominal = that.edit_a_phase_nominal;
          that.meterMeta.b_phase_nominal = that.edit_b_phase_nominal;
          that.meterMeta.c_phase_nominal = that.edit_c_phase_nominal;
          that.meterMeta.transformer_type = that.edit_transformer_type;
        }

      }).catch(function (error) {
            console.log(error);
          });
    }
  },
  mounted() {
    console.log("Meter Meta Edit")
    this.edit_a_phase_nominal = this.meterMeta.a_phase_nominal;
    this.edit_b_phase_nominal = this.meterMeta.b_phase_nominal;
    this.edit_c_phase_nominal = this.meterMeta.c_phase_nominal;
    this.edit_transformer_type = this.meterMeta.transformer_type;

  },
  components: {

  }

}
</script>
