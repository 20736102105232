<template>
  <div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
    <div id="happy-chart" style="min-width: 1200px; min-height: 700px"></div>
    <div id="kress-chart" style="min-width: 1200px; min-height: 700px"></div>
  </div>
</template>

<script>
  import axios from "axios";
  import Plotly from "plotly.js-dist"

  export default {
    name: 'DeliveryPointSummary',
    props: {
      entityId: {
        type: String,
        required: true
      },
      entityName: {
        type: String,
        required: true
      }
    },
    data: () => ({
      deliveryPoints: {},
      loading: true,
      happyWatts: {},
      kressWatts: {},
      happyChart: {happy_meta: {name: "Happy Combined Watts"}},
      kressChart: {kress_meta: {name: "Kress Combined Watts"}}
    }),
    computed: {},
    methods: {
      getDeliveryPointSummary(){
        let that = this;
        axios.get(`${that.$store.state.host}/api/v1.0/survalent_entities/delivery_point_summary/${that.entityId}`)
            .then(function (response) {
              that.happyWatts = response.data.happy;
              that.kressWatts = response.data.kress;
              that.loading = false;
              that.createChart();
            })
            .catch(function (error) {
              console.log(error);
            });
      },
      createChart() {
        const ctx = document.getElementById("happy-chart");
        let trace = {
          type: "scatter",
          mode: "lines",
          name: 'Watts',
          x: this.happyWatts.x,
          y: this.happyWatts.y,
          line: {color: '#1795cf'}};
        let layout = {
          title: this.happyChart.happy_meta.name,
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero'
          }
        };
        let data = [trace];
        this.happyChart = Plotly.newPlot(ctx,
                data,
                layout);

        const ctx2 = document.getElementById("kress-chart");
        let trace2 = {
          type: "scatter",
          mode: "lines",
          name: 'Watts',
          x: this.kressWatts.x,
          y: this.kressWatts.y,
          line: {color: '#cf6417'}};
        let layout2 = {
          title: this.kressChart.kress_meta.name,
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero'
          }
        };
        let data2 = [trace2];
        this.kressChart = Plotly.newPlot(ctx2,
            data2,
            layout2);
      },
    },
    mounted() {
      this.getDeliveryPointSummary();
      console.log("Deliery Point Summary")
    },

  }
</script>
