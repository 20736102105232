<template>
  <div>
    <v-alert type="error" v-if="dataNotFound">
      No blink data found for {{picker}}
    </v-alert>
    <v-row>
      <v-col cols="12" sm="6" md="4" style="z-index: 9000">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="picker"
                label="Daily Summary Date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="picker" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="meterVoltageLoading">
          <v-card-title>
            # of Meters Blinked Per Substation
          </v-card-title>
          <bar-graph :trace="substationBlinkTrace" graph-name="substationBlinkMetersGraph"></bar-graph>
        </v-card>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="meterVoltageLoading">
          <v-card-title>
            # of Meters Blinked Per Feeder
          </v-card-title>
          <bar-graph :trace="feederBlinkTrace" graph-name="feederBlinkMetersGraph"></bar-graph>
        </v-card>

      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col>
      <v-card :loading="meterVoltageLoading">
        <v-card-title>
          # of Low Voltage Meters Per Substation
        </v-card-title>
      <bar-graph :trace="substationLowVoltageTrace" graph-name="substationLowVoltageMetersGraph"></bar-graph>
      </v-card>
      </v-col>
      <v-col>
          <v-card :loading="meterVoltageLoading">
            <v-card-title>
              # of Low Voltage Meters Per Feeder
            </v-card-title>
            <bar-graph :trace="feederLowVoltageTrace" graph-name="feederLowVoltageMetersGraph"></bar-graph>
          </v-card>
        </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="meterVoltageLoading">
          <v-card-title>
            # of High Voltage Meters Per Substation
          </v-card-title>
          <bar-graph :trace="substationHighVoltageTrace" graph-name="substationHighVoltageMetersGraph"></bar-graph>
        </v-card>
      </v-col>

      <v-col>
        <v-card :loading="meterVoltageLoading">
          <v-card-title>
            # of High Voltage Meters Per Feeder
          </v-card-title>
          <bar-graph :trace="feederHighVoltageTrace" graph-name="feederHighVoltageMetersGraph"></bar-graph>
        </v-card>
      </v-col>

    </v-row>
    <v-row>

    </v-row>

  </div>
</template>

<script>
  import axios from 'axios'
  import BarGraph from "@/components/BarGraph";

  export default {
    name: 'DailySummary',
    data: () => ({
      dailySummary: {},
      substationLowVoltageTrace: {},
      substationHighVoltageTrace: {},
      feederLowVoltageTrace: {},
      feederHighVoltageTrace: {},

      substationBlinkTrace: {},
      feederBlinkTrace: {},

      meterVoltageLoading: false,
      picker: new Date().toISOString().substr(0, 10),
      menu: false,
      dataNotFound: false,
      entityLoaded: false
    }),
    props: {
      summary_date: {
        type: String,
        required: false
      }
    },
    methods: {
      getDailySummary(){
        let that = this
        that.meterVoltageLoading = true;
        axios.get(`${this.$store.state.host}/api/v1.0/summary/daily_summary/` + this.picker)
                .then(function (response) {
                  that.dailySummary = response.data.daily_summary

                  that.substationLowVoltageTrace = {x: that.dailySummary.meter_voltage_summary.low_counts.substation_count.x,
                    y: that.dailySummary.meter_voltage_summary.low_counts.substation_count.y,
                  name: "Daily Low Voltage Substation Meter Counts",
                  type: "bar"}

                  that.substationHighVoltageTrace = {x: that.dailySummary.meter_voltage_summary.high_counts.substation_count.x,
                    y: that.dailySummary.meter_voltage_summary.high_counts.substation_count.y,
                    name: "Daily High Voltage Substation Meter Counts",
                    type: "bar"}

                  that.feederLowVoltageTrace = {x: that.dailySummary.meter_voltage_summary.low_counts.feeder_count.x,
                    y: that.dailySummary.meter_voltage_summary.low_counts.feeder_count.y,
                    name: "Daily Low Voltage Feeder Meter Counts",
                    type: "bar"}

                  that.feederHighVoltageTrace = {x: that.dailySummary.meter_voltage_summary.high_counts.feeder_count.x,
                    y: that.dailySummary.meter_voltage_summary.high_counts.feeder_count.y,
                    name: "Daily High Voltage Feeder Meter Counts",
                    type: "bar"}

                  that.substationBlinkTrace = {x: that.dailySummary.meter_blink_summary.blink_counts.substation_count.x,
                    y: that.dailySummary.meter_blink_summary.blink_counts.substation_count.y,
                    name: "Daily Substation Blink Meter Counts",
                    type: "bar"}

                  that.feederBlinkTrace = {x: that.dailySummary.meter_blink_summary.blink_counts.feeder_count.x,
                    y: that.dailySummary.meter_blink_summary.blink_counts.feeder_count.y,
                    name: "Daily Substation Blink Meter Counts",
                    type: "bar"}


                  that.meterVoltageLoading = false;
                })
                .catch(function (error) {
                  console.log(error);
                  that.dataNotFound = true;
                  that.meterVoltageLoading = false;
                });
      },
    },
    mounted() {
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.picker = today.toISOString().substr(0, 10)
      if(this.summary_date) {
        this.picker = this.summary_date;
      }

      this.getDailySummary()

    },
    watch: {
      $route(to, from) {
        this.getDailySummary()
        this.picker = this.summary_date
        //this.$router.push({ path: `/summary/daily_summary/${this.summary_date}` })
      },
      picker(val){
        if(this.summary_date !== val) {
          this.$router.push({path: `/summary/daily_summary/${val}`})
        }
      }
    },
    components: {
      BarGraph: BarGraph
    }
  }
</script>
