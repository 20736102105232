<template>
<!--  style="min-width: 1200px; min-height: 700px"-->
    <div :id="graphName" style="min-width: 300px; min-height: 400px">
    </div>
</template>

<script>
  import Plotly from "plotly.js-dist";
  export default {
    name: 'BarGraph',
    data: () => ({
      meterChart: {}
    }),
    props: {
      trace: {
        type: Object,
        required: true
      },
      graphName: {
        type: String,
        required: true
      }
    },
    watch: {
      trace(){
        console.log("trace changed");
        this.createChart();
      }
    },
    computed:{
    },
    methods:{
      createChart() {
        console.log("Creating Bar Graph")
        const ctx = document.getElementById(this.graphName);

        let data = [this.trace];
        this.meterChart = Plotly.newPlot(ctx, data);
      },
    },
    mounted() {
      console.log("Bar Graph")
      this.$nextTick(() => {
        this.createChart();
      })

    },
  }
</script>
