<template>
  <div class="home">
    <h1>HOME</h1>

  </div>
</template>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import {mapActions} from "vuex";
  import axios from "axios";

  export default {
    name: 'home',
    data: () => ({
      username: "",
      password: "",
      files: [],
    }),
    methods: {
      sayHello(test){
        console.log("Hello ", this.username, this.password);
        let loginFormData = new FormData();
        loginFormData.set('username', this.username);
        loginFormData.set('password', this.password);
        axios({
          method: 'post',
          url: `${this.$store.state.host}/token`,
          data: loginFormData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data' }
        })
                .then(function (response) {
                  //handle success
                  console.log(response);
                  if(response.status === 200){

                  }
                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });
      },

      ...mapActions([
        'loadTreeView'
      ])
    },

    components: {
      HelloWorld
    }
  }
</script>
