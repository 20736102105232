<template>
  <div>
    <v-row>
      <v-card>
        <v-card-title>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
            <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="operations_records"
                    :items-per-page="30"
                    class="elevation-1"
            ></v-data-table>
        <v-card-actions>
          <vue-json-to-csv :json-data="operations_records" :csv-title="'Operations Log'">
            <v-btn>
              Download as CSV
            </v-btn>

          </vue-json-to-csv>


        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
  import VueJsonToCsv from 'vue-json-to-csv';
  export default {
    name: 'SurvalentOperationsTable',
    data: () => ({
      search: '',
      headers: [
        {
          text: "Time",
          value: "record_dt"
        },
        {
          text: "Point",
          value: "point"
        },
        {
          text: "Message",
          value: "message"
        },
        {
          text: "Operator",
          value: "operator"
        },
        {
          text: "Cleared",
          value: "clear"
        }

      ],

      panel: [0,1],
      menu: false,
      meterChart: {}
    }),
    props: {
      operations_records: {
        type: Array,
        required: true
      }
    },
    computed:{
    },
    methods:{


    },
    mounted() {
      console.log("SurvalentOperationsTable");
    },
    components:{
      VueJsonToCsv
    },

  }
</script>
