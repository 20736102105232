<template>
  <div>
    <h1>Map Test</h1>
    <div id="mapid"></div>
    <v-btn v-on:click="changeColor">Change Color</v-btn>
  </div>
</template>

<script>
  import L from "leaflet"
  import chroma from "chroma-js"
  import axios from "axios";
  L.Icon.Default.imagePath = '.';
  export default {
    name: 'MapTest',
    data: () => ({
      customColor: "#ff0000",
      capacitors: [],
      milsoftGeoJson: {},
      conductorGeoJson: {},
      sourceGeoJson: {},
      sourceLayer: {},
      conductorLayer: {},
      milsoftMap: {}
    }),
    computed:{
    },
    methods:{
      getCapacitors(){
      },
      getMilsoft(){
        let that = this
        axios.get(`${this.$store.state.host}/api/v1.0/milsoft/geojson`, {params:{refresh_cache: false}})
            .then(function (response) {
              let milsoftGeoJson = response.data.milsoftGeoJson;
              let conductorGeoJson = JSON.parse(milsoftGeoJson.conductors);
              let sourceGeoJson = JSON.parse(milsoftGeoJson.sources);
              that.conductorLayer.addData(conductorGeoJson);

              var geojsonMarkerOptions = {
                radius: 8,
                fillColor: "#090808",
                color: "#000",
                weight: 1,
                opacity: 1,
                fillOpacity: 0.95
              };

              function onEachFeature(feature, layer) {
                // does this feature have a property named popupContent?
                if (feature.properties && feature.properties.name) {
                  layer.bindPopup(feature.properties.name);
                  //layer.bindTooltip(feature.properties.name, {permanent: true})
                }
              }

              function pointToLayer(feature, latlng){
                return L.circleMarker(latlng, geojsonMarkerOptions);
              }

              that.sourceLayer = L.geoJSON(sourceGeoJson, {pointToLayer: pointToLayer}).addTo(that.milsoftMap);

              //that.sourceLayer.addData(that.sourceGeoJson);

            })
            .catch(function (error) {
              console.log(error);
            });
      },
      changeColor(){
        //this.conductorLayer.setStyle({color: "#00ff0f"})
        console.log("pressed")
      }
    },
    mounted() {
      var that = this;
      console.log("Map Test")
      this.milsoftMap = L.map('mapid', {preferCanvas: true}).setView([34.535756, -101.768577], 11);
      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoiY29zbTAxNTYiLCJhIjoiY2s2NnljanZqMXF4ZzNrcW9sdTIyZXIxaSJ9.8hzDkBwyf1nPzeBWRwW0rw'
      }).addTo(this.milsoftMap);

      this.conductorLayer = L.geoJSON(null,{style: {color: "#2594b8"}}).addTo(this.milsoftMap);

      this.getMilsoft();
    },

  }
</script>
<style>
  /* global styles */

  #mapid { height: 680px; }
</style>