<template>
  <div>
    <h1>{{ relay_object.relay_name }}</h1>

    <v-expansion-panels multiple
                        v-model="panel">

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Relay Command Data</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels>
            <v-expansion-panel v-for="command in relayData" :key="command[3]">
              <v-expansion-panel-header>{{command[2]}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels multiple>
                <v-expansion-panel v-for = "relay_command in Object.entries(command[1].raw_relay_data)" :key="relay_command[0]">
                  <v-expansion-panel-header>
                  {{relay_command[0]}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea :value="relay_command[1]" auto-grow style="font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace">
                    </v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>

              </v-expansion-panel-content>


            </v-expansion-panel>

          </v-expansion-panels>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'rawRelayData',
  data: () => ({
    relay_object: {},
    relayLoaded: false,
    relayData: {},
    panel: [0]
  }),
  props:{
    relayId: {
      type: String,
      required: true
    },
  },
  methods: {
    getRelay(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/pyrelay/` + this.relayId)
          .then(function (response) {
            that.relay_object = response.data.relay
            that.getRelayData()
            that.relayLoaded = true;

          })
          .catch(function (error) {
            console.log(error);
          });

    },
    getRelayData(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/pyrelay/` + this.relayId  + "/data")
          .then(function (response) {
            that.relayData = response.data.relay_data
          })
          .catch(function (error) {
            console.log(error);
          });

    },
  },
  mounted() {
    this.relayId = this.$route.params.relayId
    this.getRelay()

  },
  watch: {
    $route(to, from) {
      this.relayId = this.$route.params.relayId
      this.getRelay()
    }

  },
  components: {
  }
}
</script>

<style scoped>
textarea{
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
</style>