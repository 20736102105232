<template>
  <div>

  <v-col cols="12" sm="6" md="4">
    <div id="meter-chart" style="min-width: 1200px; min-height: 700px">
    </div>
  </v-col>
    <v-row>
      <v-expansion-panels
              v-model="panel"
              multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Voltage Records</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                    :headers="headers"
                    :items="voltage_records"
                    :items-per-page="10"
                    sort-by="time_checked"
                    :sort-desc=true
                    class="elevation-1"
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
  import Plotly from "plotly.js-dist";

  export default {
    name: 'MeterVoltageGraph',
    data: () => ({
      headers: [
        {
          text: "Meter #",
          value: "meter_number"
        },
        {
          text: "Time Checked",
          value: "time_checked"
        },
        {
          text: "Substation",
          value: "substation"
        },
        {
          text: "Feeder",
          value: "feeder"
        },
        {
          text: "Voltage",
          value: "voltage"
        },
        {
          text: "% of Nominal",
          value: "voltage_percent_of_nominal"
        },
        {
          text: "A-Phase Voltage",
          value: "voltage_a"
        },
        // {
        //   text: "A % of Nominal",
        //   value: "voltage_a_percent_of_nominal"
        // },
        {
          text: "B-Phase Voltage",
          value: "voltage_b"
        },
        // {
        //   text: "B % of Nominal",
        //   value: "voltage_b_percent_of_nominal"
        // },
        {
          text: "C-Phase Voltage",
          value: "voltage_c"
        },
        // {
        //   text: "C % of Nominal",
        //   value: "voltage_c_percent_of_nominal"
        // }

      ],
      dialog: false,
      meterMeta: {},
      panel: [0,1],
      picker: new Date().toISOString().substr(0, 10),
      menu: false,
      meterChart: {}
    }),
    props: {
      meter_number: {
        type: String,
        required: true
      },
      voltage_records: {
        type: Array,
        required: true
      },
    },
    computed:{
    },
    methods:{
      createChart() {
        console.log("Creating Meter Chart")
        const ctx = document.getElementById("meter-chart");
        let ys_a = this.voltage_records.map(x => x.voltage_a);
        let ys_b = this.voltage_records.map(x => x.voltage_b);
        let ys_c = this.voltage_records.map(x => x.voltage_c);
        let xs = this.voltage_records.map(x => x.time_checked);
        let trace_a = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage A',
          x: xs,
          y: ys_a,
          line: {color: '#cf1717'}};
        let trace_b = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage B',
          x: xs,
          y: ys_b,
          line: {color: '#1736cf'}};
        let trace_c = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage C',
          x: xs,
          y: ys_c,
          line: {color: '#217113'}};
        let layout = {
          title: "Voltage -- Meter #: " + this.meter_number,
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero',
            title: {
              text: "Voltage (V)"
            }
          }
        };
        let data = [trace_a, trace_b, trace_c];
        this.meterChart = Plotly.newPlot(ctx,
                data,
                layout);
      },
    },
    mounted() {
      console.log("Meter Voltage Graph")
      this.createChart()
    },

  }
</script>
