<template>
  <div>
    <v-card>
      <v-card-title>
        Meter Search
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="meterList"
          item-key="meter_number"
          :items-per-page="30"
          :loading="loading"
          :search="search"
          class="elevation-1">
        <template slot="item.meter_number" slot-scope="slotProps">
          <router-link :to="{name: 'meterDetail', params: {meter_number: slotProps.item.meter_number}}" tag="a">
            {{ slotProps.item.meter_number }}</router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'MeterSearch',
  data: () => ({
    loading: true,
    search: "",
    headers: [{text: "Meter Number", value: "meter_number"},
      {text: "Service Location", value: "service_location"},
      {text: "Customer Number", value: "customer_number"},
      {text: "Transformer Grid Number", value: "transformer_grid_number"}],
    meterList: []

  }),
  props: {

  },
  computed:{
  },
  methods:{
    getMeters(){
      let that = this;
      that.loading = true;
      axios.get(`${this.$store.state.host}/api/v1.0/meter_info`)
          .then(function (response) {
            that.meterList = response.data.meter_list;
            that.loading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    createChart() {
    },
  }
  ,
  mounted() {
    console.log("Meter Search");
    this.getMeters();
  },
  components: {
  }

}
</script>
