<template>
  <div>
    <survalent-operations-table  :operations_records="operations_records"></survalent-operations-table>
  </div>
</template>

<script>
import axios from "axios";
import MeterVoltageGraph from "../components/MeterVoltageGraph";
import MeterBlinkGraph from "../components/MeterBlinkGraph";
import MeterBillingGraph from "@/components/MeterBillingGraph";
import MeterMetaEdit from "@/components/MeterMetaEdit";
import SurvalentOperationsTable from "@/components/SurvalentOperationsTable.vue";


export default {
  name: 'SurvalentOperations',
  data: () => ({
    operations_records: [],
  }),
  computed:{
  },
  methods:{
    getOperationsLog(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/operations_log`)
          .then(function (response) {
            if(response.data.hasOwnProperty('operations_records'))
            {
              that.operations_records = response.data.operations_records;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
  ,
  mounted() {
    console.log("Survalent Operations View")
    this.getOperationsLog();
  },
  components: {
    SurvalentOperationsTable: SurvalentOperationsTable,
  }

}
</script>
