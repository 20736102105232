<template>
  <!-- App.vue -->
  <v-app>

    <v-navigation-drawer
        v-if="authenticated"
        app
        dark
        :width="340"
        >
      <v-card
          class="mx-auto"
          max-width="500"
      >
        <v-sheet class="pa-4 primary lighten-2">
          <v-text-field
              v-model="search"
              label="Search Project"
              dark
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
        <v-card-text>
          <v-treeview
              :items="projectTreeView"
              :search="search"
              :filter="filter"
              activatable
              @update:active="treeviewActive"
              selection-type="independent"
              hoverable
          >
            <template v-slot:prepend="{ item }">
              <v-icon
                  v-if="item.children"
                  v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"
              ></v-icon>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <router-link :to="{name:'home'}">
        <v-icon>mdi-home</v-icon>
      </router-link>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="goToAccount"
             v-if="authenticated">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <router-link :to="{name:'login'}" v-if="!authenticated">
        Login
      </router-link>
      <v-btn rounded color="primary" dark v-if="authenticated" @click="logout">Logout</v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-8">

        <!-- If using vue-router -->
        <router-view v-if="authenticated"></router-view>
        <login v-if="!authenticated"></login>

      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>

</template>
<script>
import HelloWorld from './components/HelloWorld';
import Login from "./views/Login";
import { mapState, mapActions } from 'vuex';
import axios from "axios";
import Vue from "vue";

export default {
  name: 'App',
  data: () => ({
    search: null,
    user: {},

  }),
  methods: {
    treeviewActive(active_ids){
      if(active_ids.length === 0){

      }
      else{
        let active_id = active_ids[0]
        if (active_id === 1){

        }
        else if (active_id === 2) {

        }
        else if (active_id === 3) {

        }
        else if (active_id === 4) {

        }
        else if (active_id === 5) {

        }
        else if (active_id === 6) {

        }
        else if (active_id === 7) {
          this.$router.push({name: 'meterVoltageOverview'})

        }
        else if (active_id === 8) {
          this.$router.push({name: 'meterBlinkOverview'})

        }
        else if (active_id === 9) {
          this.$router.push({name: 'meterUploads'})
        }

        else if (active_id === 10) {
          this.$router.push({name: 'dailySummary'})
        }
        else if (active_id === 11) {
          this.$router.push({name: 'meterSearch'})
        }
        else if (active_id === 12) {
          this.$router.push({name: 'meterSystemMap'})
        }
        else if (active_id === 13) {
        }
        else if (active_id === 14) {
          this.$router.push({name: 'dailyReportDate'})
        }
        else if (active_id === 15) {
          this.$router.push({name: 'survalentOperations'})
        }
        else {

          for (let relay of this.projectTreeView[5].children){
            if (relay.id === active_id){
              this.$router.push({name: 'relay', params: {relayId:active_id}})
              return;
            }
          }
          this.$router.push({name: 'entity', params: {entityId:active_id}})
        }
        console.log(active_id)
      }

    },
    sayHello(test){
      console.log("Hello ", this.active, test)
    },
    getUser(){
      let that = this;
      axios.get(`${this.$store.state.host}/users/me`)
          .then(function (response) {
            that.user = response.data
            that.setAuthenticated(true)
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
            that.$router.push({'name': 'login'})
          });
    },
    logout(){
      console.log("logout")
      localStorage.removeItem('token')
      Vue.prototype.$http.defaults.headers.common['Authorization'] = null
      this.$store.commit('setAuthenticated', false)
      this.$router.push({'path': '/logout'})
    },
    goToAccount(){
      this.$router.push({'name': 'userAccount'}).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    ...mapActions([
      'loadTreeView',
      'setAuthenticated'
    ])
  },
  computed: {
    filter() {
      return false ? (item, search, textKey) => item[textKey].indexOf(search) > -1 : undefined
    },
    // mix this into the outer object with the object spread operator
    ...mapState([
      'projectTreeView',
      'authenticated'
    ])
  },
  components: {
    HelloWorld,
    Login,
  },
  watch: {
    $route(to, from) {
      console.log(to)
      console.log(from)
      this.loadTreeView()
    }
  },
  mounted(){
    console.log("Mounted")
    console.log(this.authenticated)
    this.getUser()
    this.sayHello()
    this.loadTreeView()
  }
};
</script>
