<template>
  <div>
    <h1>{{ entity_object.name }}</h1>
    <v-expansion-panels
        :multiple=true
    :value="[1,2]">
      <v-expansion-panel>
      <v-expansion-panel-header>Properties</v-expansion-panel-header>
      <v-expansion-panel-content>
        <entity-properties :entity="entity_object" :exclude="['switch_in', 'switch_out', 'switching']"></entity-properties>
      </v-expansion-panel-content>
    </v-expansion-panel>
      <v-expansion-panel>
      <v-expansion-panel-header>Metrics</v-expansion-panel-header>
      <v-expansion-panel-content>
        <entity-metrics :entity="entity_object"></entity-metrics>
      </v-expansion-panel-content>
    </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Summaries</v-expansion-panel-header>
        <v-expansion-panel-content v-if="entity_object.entity_type === 'Feeder'">
          <router-link :to="{name: 'feederAmps', params: { entityId: entity_object.entity_id, entityName: entity_object.name }}">
            <p>Feeder Amps</p>
          </router-link>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="entity_object.entity_type === 'System'">
          <router-link :to="{name: 'deliveryPointSummary', params: { entityId: entity_object.entity_id, entityName: entity_object.name }}">
            <p>Delivery Point Summary</p>
          </router-link>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
<!--    <watts-vars-pf-graph v-if="entityLoaded" :entity-id="pathEntityId" ></watts-vars-pf-graph>-->
  </div>
</template>

<script>
  import axios from 'axios'
  import EntityProperties from "../components/EntityProperties";
  import EntityMetrics from "../components/EntityMetrics";
  import WattsVarsPfGraph from "@/components/WattsVarsPfGraph";
  export default {
    name: 'home',
    data: () => ({
      milsoft_name: "Milsoft Name",
      pathEntityId: "",
      entity_object: {},
      entityLoaded: false
    }),
    methods: {
      getEntity(){
        let that = this
        axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/` + this.pathEntityId)
                .then(function (response) {
                  that.entity_object = response.data.entity
                  that.entityLoaded = true;

                })
                .catch(function (error) {
                  console.log(error);
                });

      },
    },
    mounted() {
      this.pathEntityId = this.$route.params.entityId
      this.getEntity()

    },
    watch: {
      $route(to, from) {
        this.pathEntityId = this.$route.params.entityId
        this.getEntity()
      }

    },
    components: {
      EntityProperties: EntityProperties,
      EntityMetrics: EntityMetrics,
      WattsVarsPfGraph: WattsVarsPfGraph
    }
  }
</script>
