<template>
  <div>
  <v-col cols="12" sm="6" md="4">
    <div id="meter-billing-chart" style="min-width: 1200px; min-height: 700px">
    </div>
  </v-col>
    <v-row>
      <v-expansion-panels
              v-model="panel"
              multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Billing Records</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                    :headers="headers"
                    :items="billing_records"
                    :items-per-page="10"
                    sort-by="time_checked"
                    :sort-desc=true
                    class="elevation-1"
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
  import Plotly from "plotly.js-dist";

  export default {
    name: 'MeterBillingGraph',
    data: () => ({
      headers: [
        {
          text: "Meter #",
          value: "meter_number"
        },
        {
          text: "Time Checked",
          value: "time_checked"
        },
        {
          text: "kWh",
          value: "kwh"
        },
        {
          text: "kW demand",
          value: "kw_demand"
        },
        {
          text: "kVAR",
          value: "kvar"
        },
        {
          text: "Power Factor",
          value: "pf"
        },
        {
          text: "Response Status",
          value: "response_status"
        },

      ],

      panel: [0,1],
      menu: false,
      meterChart: {}
    }),
    props: {
      meter_number: {
        type: String,
        required: true
      },
      billing_records: {
        type: Array,
        required: true
      },
      billing_diff_records: {
        type: Array,
        required: true
      }
    },
    computed:{
    },
    methods:{
      createChart() {
        console.log("Creating Meter Billing Chart")
        const ctx = document.getElementById("meter-billing-chart");
        let ys = this.billing_diff_records.map(x => x.daily_kwh_usage);
        let xs = this.billing_diff_records.map(x => x.time_checked);
        let trace = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage',
          x: xs,
          y: ys,
          line: {color: '#17BECF'}};
        let layout = {
          title: "Daily Energy Usage (kWh) -- Meter #: " + this.meter_number,
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero',
            title: {
              text: "Energy (kWh)"
            }
          }
        };
        let data = [trace];
        this.meterChart = Plotly.newPlot(ctx,
                data,
                layout);
      },
    }
    ,
    mounted() {
      console.log("Meter Billing Graph");
      this.createChart();
    },

  }
</script>
