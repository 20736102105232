<template>
  <div>
    <div id="vssi-chart" style="min-width: 1400px; min-height: 900px">
    </div>

  </div>
</template>

<script>
  import Plotly from "plotly.js-dist";

  export default {
    name: 'RelayVssiGraph',
    data: () => ({
      vssiChart: {}
    }),
    props: {
      relay: {
        type: Object,
        required: true
      },
      vssi_records: {
        type: Object,
        required: true
      },
    },
    computed:{
    },
    methods:{
      createChart() {
        console.log("Creating VSSI Chart")
        const ctx = document.getElementById("vssi-chart");
        let ys_va = this.vssi_records.voltage_a
        let ys_vb = this.vssi_records.voltage_b
        let ys_vc = this.vssi_records.voltage_c
        let ys_ia = this.vssi_records.current_a
        let ys_ib = this.vssi_records.current_b
        let ys_ic = this.vssi_records.current_c
        let ys_ig = this.vssi_records.current_g
        let ys_in = this.vssi_records.current_n
        let xs = this.vssi_records.time
        let trace_va = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage A',
          yaxis: 'yaxisVoltage',
          x: xs,
          y: ys_va,
          line: {color: '#cf1717'}};
        let trace_vb = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage B',
          yaxis: 'yaxisVoltage',
          x: xs,
          y: ys_vb,
          line: {color: '#1736cf'}};
        let trace_vc = {
          type: "scatter",
          mode: "lines",
          name: 'Voltage C',
          yaxis: 'yaxisVoltage',
          x: xs,
          y: ys_vc,
          line: {color: '#31b71a'}};
        let trace_ia = {
          type: "scatter",
          mode: "lines",
          name: 'Current A',
          yaxis: 'yaxisCurrent',
          x: xs,
          y: ys_ia,
          line: {color: '#c44545'}};
        let trace_ib = {
          type: "scatter",
          mode: "lines",
          name: 'Current B',
          yaxis: 'yaxisCurrent',
          x: xs,
          y: ys_ib,
          line: {color: '#178fcf'}};
        let trace_ic = {
          type: "scatter",
          mode: "lines",
          name: 'Current C',
          yaxis: 'yaxisCurrent',
          x: xs,
          y: ys_ic,
          line: {color: '#46a620'}};
        let trace_ig = {
          type: "scatter",
          mode: "lines",
          name: 'Current Ground',
          yaxis: 'yaxisCurrent',
          x: xs,
          y: ys_ig,
          line: {color: '#e19017'}};
        let trace_in = {
          type: "scatter",
          mode: "lines",
          name: 'Current Neutral',
          yaxis: 'yaxisCurrent',
          visible: 'legendonly',
          x: xs,
          y: ys_in,
          line: {color: '#e19017'}};

        let layout = {
          title: "VSSI " + this.relay.relay_name,
          yaxisVoltage: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero',
            title: {
              text: "Voltage (V)"
            }
          },
          yaxisCurrent: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero',
            title: {
              text: "Current (Amps)"
            }
          },
        };
        let data = [trace_va, trace_vb, trace_vc, trace_ia, trace_ib, trace_ic, trace_ig, trace_in];
        this.vssiChart = Plotly.newPlot(ctx,
                data,
                layout);
      },
    },
    mounted() {
      console.log("Relay VSSI Graph")
      this.createChart()
    },

  }
</script>
