<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="report"
        :items-per-page="50"
        sort-by="name"
        :sort-desc=false
        class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>

export default {
  name: 'DailyLDPReport',
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Time",
        value: "time"
      },
      {
        text: "Daily Mwh",
        value: "Daily_MWh"
      },
      {
        text: "Mwh Dial",
        value: "MWh[0][1]"
      }
    ],
  }),
  props: {
    report: {
      type: Array,
      required: true
    },
  },
  computed:{
  },
  methods:{

  },
  mounted() {
  },

}
</script>
