<template>
    <div id="voltageMapId"></div>
</template>

<script>
import L from "leaflet"
import chroma from "chroma-js"
import axios from "axios";
L.Icon.Default.imagePath = '.';
export default {
  name: 'MeterVoltageMap',
  data: () => ({
    high_geojson: {},
    low_geojson: {},
    highVoltageLayer: {},
    lowVoltageLayer: {},
    myMap: {}
  }),
  props:{
    picker: {
      type: String,
      required: true
    },
  },
  watch: {
    picker(val){
      this.getMeterVoltages(val);
    }
  },
  computed:{
  },
  methods:{
    getMeterVoltages(meterDate){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/meter_voltages/${meterDate}`)
          .then(function (response) {
            console.log(response)
            that.high_geojson = response.data.high_geojson;
            that.low_geojson = response.data.low_geojson;
            function onEachFeature(feature, layer) {
              if (feature.properties) {
                layer.bindPopup("map location : " + feature.properties.name +
                    "<br> voltage_a : " + feature.properties.voltage_a +
                    "<br> voltage_b : " + feature.properties.voltage_b +
                    "<br> voltage_c : " + feature.properties.voltage_c +
                    "<br> meter_number : " + "<a href='#/meter/"+feature.properties.meter_number + "'>" +feature.properties.meter_number + "</a>");
              }
            }
            let highGeojsonMarkerOptions = {
              radius: 8,
              fillColor: "#dc0e25",
              color: "#000000",
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8
            };
            let lowGeojsonMarkerOptions = {
              radius: 8,
              fillColor: "#2156c4",
              color: "#000000",
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8
            };

            that.myMap.removeLayer(that.highVoltageLayer);
            that.myMap.removeLayer(that.lowVoltageLayer);
            that.highVoltageLayer = L.geoJSON(that.high_geojson,{
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, highGeojsonMarkerOptions);
              },
              onEachFeature: onEachFeature
            }).addTo(that.myMap);

            that.lowVoltageLayer = L.geoJSON(that.low_geojson,{
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, lowGeojsonMarkerOptions);
              },
              onEachFeature: onEachFeature
            }).addTo(that.myMap);
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
  ,
  mounted() {
    console.log("Meter Voltage Map")

    let mymap = L.map('voltageMapId').setView([34.535756, -101.768577], 10);
    this.myMap = mymap;

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiY29zbTAxNTYiLCJhIjoiY2s2NnljanZqMXF4ZzNrcW9sdTIyZXIxaSJ9.8hzDkBwyf1nPzeBWRwW0rw'
    }).addTo(mymap);

    this.getMeterVoltages(this.picker)

  },

}
</script>
<style>
/* global styles https://robertleeplummerjr.github.io/Leaflet.glify/ */

#voltageMapId { height: 680px; }
</style>