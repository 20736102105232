<template>
  <div class="login">
    <v-row justify="center">
      <v-card class="elevation-12" max-width="800" min-width="400">
        <v-toolbar
                color="primary"
                dark
                flat
        >
          <v-toolbar-title>Login form</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-form
          ref="loginForm">
            <v-text-field
                    label="Login"
                    name="login"
                    prepend-icon="mdi-person"
                    type="text"
                    v-model="username"
            />
            <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary"
          @click="login()">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
  // @ is an alias to /src
  import {mapActions} from "vuex";
  import Vue from "vue";

  export default {
    name: 'login',
    data: () => ({
      username: "",
      password: ""
    }),
    methods: {
      login(test){
        let that = this
        let loginFormData = new FormData();
        loginFormData.set('username', this.username);
        loginFormData.set('password', this.password);
        axios({
          method: 'post',
          url: `${this.$store.state.host}/token`,
          data: loginFormData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data' }
        })
                .then(function (response) {
                  //handle success
                  if(response.status === 200){
                    localStorage.setItem('token', response.data.user_token)
                    Vue.prototype.$http.defaults.headers.common['Authorization'] = response.data.user_token
                    that.$store.commit('setAuthenticated', true)
                    that.$router.push({'name': 'home'})
                    that.$forceUpdate()
                  }
                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });
      },
      ...mapActions([
        'loadTreeView'
      ])
    }
  }
</script>
