<template>
  <div>
    <v-alert type="error" v-if="dataNotFound">
      No blink data found for {{picker}}
    </v-alert>
    <v-row>
      <v-col cols="12" sm="6" md="4" style="z-index: 9000">
        <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                    v-model="picker"
                    label="Blink Report Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="picker" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div id="mapid"></div>

  </div>
</template>

<script>
  import L from "leaflet"
  import chroma from "chroma-js"
  import axios from "axios";
  L.Icon.Default.imagePath = '.';
  export default {
    name: 'MeterBlinkOverview',
    data: () => ({
      dataNotFound: false,
      capacitors: [],
      picker: new Date().toISOString().substr(0, 10),
      menu: false,
      records_geo_json: {},
      blinkLayer: {},
      myMap: {}
    }),
    watch: {
      picker(val){
        this.getMeters(val);
      }
    },
    computed:{
    },
    methods:{
      getCapacitors(){
      },
      getMeters(meterDate){
        let that = this
        axios.get(`${this.$store.state.host}/api/v1.0/meters/blinks/${meterDate}`)
                .then(function (response) {
                  that.records = response.data.records;
                  that.records_geo_json = response.data.geo_json;
                  function onEachFeature(feature, layer) {
                    if (feature.properties) {
                      layer.bindPopup("map location : " + feature.properties.name +
                              "<br> blink_count : " + feature.properties.blink_count +
                              "<br> meter_number : " + "<a href='#/meter/"+feature.properties.meter_number + "'>" +feature.properties.meter_number + "</a>");
                    }
                  }
                  let blink_counts = that.records_geo_json.features.map(a => a.properties.blink_count);
                  let unique_counts = [... new Set(blink_counts)]
                  let num_unique_counts = unique_counts.length
                  let num_quantiles = 6
                  if(num_unique_counts < 5){
                    num_quantiles = num_unique_counts
                  }
                  let limits = chroma.limits(blink_counts, 'q', num_quantiles);
                  let scale = chroma.scale('Reds').padding([0.4, 0]).colors(num_quantiles);
                  let color_lookup = {}
                  for(let i = 0; i <unique_counts.length; i++){
                    for(let j = 0; j<limits.length-1; j++){
                      if(unique_counts[i] >= limits[j] && unique_counts[i] <= limits[j+1]){
                        color_lookup[unique_counts[i]] = scale[j]
                        break
                      }
                    }
                  }
                  let geojsonMarkerOptions = {
                    radius: 8,
                    fillColor: "#ff7800",
                    color: "#000",
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8
                  };
                  that.myMap.removeLayer(that.blinkLayer);
                  that.blinkLayer = L.geoJSON(that.records_geo_json,{
                    pointToLayer: function (feature, latlng) {
                      geojsonMarkerOptions.color="#000000"
                      geojsonMarkerOptions.fillColor=color_lookup[feature.properties.blink_count]
                      return L.circleMarker(latlng, geojsonMarkerOptions);
                    },
                    onEachFeature: onEachFeature
                  }).addTo(that.myMap);
                  that.dataNotFound = false;
                })
                .catch(function (error) {
                  console.log(error);
                  that.dataNotFound = true;
                });
      }
    }
    ,
    mounted() {
      console.log("Meter Blink Overview")
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.picker = today.toISOString().substr(0, 10)
      let mymap = L.map('mapid').setView([34.535756, -101.768577], 10);
      this.myMap = mymap;
      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoiY29zbTAxNTYiLCJhIjoiY2s2NnljanZqMXF4ZzNrcW9sdTIyZXIxaSJ9.8hzDkBwyf1nPzeBWRwW0rw'
      }).addTo(mymap);
      this.getMeters(this.picker)

      function onEachFeature(feature, layer) {
        // does this feature have a property named popupContent?
        if (feature.properties) {
          layer.bindPopup("map location : " + feature.properties.name + "<br> blink_count : " + feature.properties.blink_count);
          // + "<br>"+feature.properties.substation +" " + feature.properties.feeder
        }
      }
      let blink_counts = this.records_geo_json.features.map(a => a.properties.blink_count);
      let unique_counts = [... new Set(blink_counts)]
      let num_unique_counts = unique_counts.length
      let num_quantiles = 6
      if(num_unique_counts < 5){
        num_quantiles = num_unique_counts
      }
      let limits = chroma.limits(blink_counts, 'q', num_quantiles);
      let scale = chroma.scale('Reds').padding([0.4, 0]).colors(num_quantiles);
      let color_lookup = {}
      for(let i = 0; i <unique_counts.length; i++){
        for(let j = 0; j<limits.length-1; j++){
          if(unique_counts[i] >= limits[j] && unique_counts[i] <= limits[j+1]){
            color_lookup[unique_counts[i]] = scale[j]
            break
          }
        }
      }
      let geojsonMarkerOptions = {
        radius: 8,
        fillColor: "#ff7800",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8
      };

      this.blinkLayer = L.geoJSON(this.records_geo_json,{
        pointToLayer: function (feature, latlng) {

          geojsonMarkerOptions.color="#000000"
          geojsonMarkerOptions.fillColor=color_lookup[feature.properties.blink_count]
          return L.circleMarker(latlng, geojsonMarkerOptions);
        },
        onEachFeature: onEachFeature
      }).addTo(mymap);

    },

  }
</script>
<style>
  /* global styles */

  #mapid { height: 680px; }
</style>