<template>
  <div>
    <h1>{{ relay_object.relay_name }}</h1>

    <v-expansion-panels multiple
                        v-model="panel">
      <v-expansion-panel v-if="relay_object.relay_type ==='SEL-734'">
        <v-expansion-panel-header>
          <h2>Relay VSSI Graph</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <relay-vssi-graph :vssi_records="relayVssiData" :relay="relay_object" v-if="relayVssiDataLoaded"></relay-vssi-graph>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Relay SER Data</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <relay-ser-table :ser_records=relaySerData :relay=relay_object></relay-ser-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Relay ID Data</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <relay-id-table :id_records=relayIdData :relay=relay_object></relay-id-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Relay Raw Data</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <router-link :to="{ name: 'relayRawData', params: {relay_id: relayId}}" ><v-btn>Raw Relay Data </v-btn></router-link>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>

<script>
import axios from 'axios'
import RelaySerTable from "@/components/RelaySerTable";
import RelayVssiGraph from "@/components/RelayVssiGraph";
import RelayIdTable from "@/components/RelayIdTable";

export default {
  name: 'home',
  data: () => ({
    relay_object: {},
    relayLoaded: false,
    relayRawData: {},
    relaySerData: [],
    relayIdData: [],
    relayVssiData: {},
    relaySerDataLoaded: false,
    relayVssiDataLoaded: false,
    relayIdDataLoaded: false,
    panel: [0,1,2,3]
  }),
  props:{
    relayId: {
      type: String,
      required: true
    },
  },
  methods: {
    getRelay(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/pyrelay/` + this.relayId)
          .then(function (response) {
            that.relay_object = response.data.relay
            that.relayLoaded = true;

          })
          .catch(function (error) {
            console.log(error);
          });

    },
    getRelaySerData(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/pyrelay/` + this.relayId  + "/ser_data")
          .then(function (response) {
            that.relaySerData = response.data.relay_ser_data
            that.relaySerDataLoaded = true
          })
          .catch(function (error) {
            console.log(error);
          });

    },
    getRelayIdData(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/pyrelay/` + this.relayId  + "/id_data")
          .then(function (response) {
            that.relayIdData = response.data.relay_id_data
            that.relayIdDataLoaded = true
          })
          .catch(function (error) {
            console.log(error);
          });

    },
    getRelayVssiData(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/pyrelay/` + this.relayId  + "/vssi_data")
          .then(function (response) {
            that.relayVssiData = response.data.relay_vssi_data
            that.relayVssiDataLoaded = true
          })
          .catch(function (error) {
            console.log(error);
          });

    },
  },
  mounted() {
    this.relayId = this.$route.params.relayId
    this.getRelay()
    this.getRelaySerData()
    this.getRelayIdData()
    this.getRelayVssiData()
    console.log(this)

  },
  watch: {
    $route(to, from) {
      this.relayId = this.$route.params.relayId
      this.relayVssiDataLoaded = false
      this.relaySerDataLoaded = false
      this.getRelay()
      this.getRelaySerData()
      this.getRelayVssiData()
    }
  },
  components: {
    RelaySerTable,
    RelayVssiGraph,
    RelayIdTable
  }
}
</script>

<style scoped>
textarea{
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
</style>