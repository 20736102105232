import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import EntityDetail from "../views/EntityDetail";
import MetricGraph from "../components/MetricGraph";
import MeterVoltageOverview from "../views/MeterVoltageOverview";
import MeterVoltageGraph from "../components/MeterVoltageGraph";
import Login from "../views/Login";
import MapTest from "../views/MapTest";
import MeterUploads from "../views/MeterUploads";
import MeterBlinkOverview from "../views/MeterBlinkOverview";
import UserAccount from "../views/UserAccount";
import MeterDetail from "../views/MeterDetail";
import DailySummary from "@/views/DailySummary";
import MeterSearch from "@/views/MeterSearch";
import MapboxTest from "@/views/MapboxTest";
import MeterBlinkMapbox from "@/views/MeterBlinkMapbox";
import FeederAmpsGraph from "@/views/FeederAmpsGraph";
import RelayDetail from "@/views/RelayDetail";
import DailyReport from "@/views/DailyReport";
import RelaySerTable from "@/components/RelaySerTable";
import RelayRawData from "@/components/RelayRawData";
import DeliveryPointSummary from "@/views/DeliveryPointSummary";
import SurvalentOperations from "@/views/SurvalentOperations.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: MeterBlinkMapbox
  },
  {
    path: '/account/home',
    name: 'userAccount',
    component: UserAccount
  },
  {
    path: '/operations_log',
    name: 'survalentOperations',
    component: SurvalentOperations
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/maptest',
    name: 'maptest',
    component: MapTest
  },
  {
    path: '/mapboxtest',
    name: 'mapboxtest',
    component: MapboxTest
  },
  {
    path: '/meters/voltages',
    name: 'meterVoltageOverview',
    component: MeterVoltageOverview
  },
  {
    path: '/meters/blinks',
    name: 'meterBlinkOverview',
    component: MeterBlinkOverview
  },
  {
    path: '/meters/system_map',
    name: 'meterSystemMap',
    component: MeterBlinkMapbox
  },
  {
    path: '/meter/:meter_number',
    name: 'meterDetail',
    props: true,
    component: MeterDetail
  },
  {
    path: '/meter_search',
    name: 'meterSearch',
    component: MeterSearch
  },
  {
    path: '/summary/daily_summary/:summary_date',
    name: 'dailySummaryDate',
    props: true,
    component: DailySummary
  },
  {
    path: '/pyreports/daily/:report_date',
    name: 'dailyReportDate',
    props: true,
    component: DailyReport
  },
  {
    path: '/summary/daily_summary/',
    name: 'dailySummary',
    props: true,
    component: DailySummary
  },
  {
    path: '/entity/:entityId',
    name: 'entity',
    component: EntityDetail
  },
  {
    path: '/meters/uploads',
    name: 'meterUploads',
    component: MeterUploads
  },
  {
    path: '/entity/:entityId/:metricName/:tableName',
    name: 'metric',
    props: true,
    component: MetricGraph
  },
  {
    path: '/feeder_amps/:entityId',
    name: 'feederAmps',
    props: true,
    component: FeederAmpsGraph
  },
  {
    path: '/deliveryPointSummary/:entityId',
    name: 'deliveryPointSummary',
    props: true,
    component: DeliveryPointSummary
  },
  {
    path: '/pyrelay/:relayId',
    name: 'relay',
    props: true,
    component: RelayDetail
  },
  {
    path: '/pyrelay/:relayId/ser',
    name: 'relaySer',
    props: true,
    component: RelaySerTable
  },
  {
    path: '/pyrelay/:relayId/rawData',
    name: 'relayRawData',
    props: true,
    component: RelayRawData
  },

]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = async function push(location) {
  try {
    return await originalPush.call(this, location);
  } catch (err) {
    if (err.name !== 'NavigationDuplicated') throw err
  }
}

const DEFAULT_TITLE = 'Relay Reporter';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router
