<template>
  <div>

    <v-col cols="12" sm="6" md="4">
      <div id="blink-chart" style="min-width: 1200px; min-height: 700px">
      </div>
    </v-col>
    <v-row>
      <v-expansion-panels
          v-model="panel"
          multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Blink Records</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="blink_records"
                :items-per-page="10"
                sort-by="time_checked"
                :sort-desc=true
                class="elevation-1"
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: 'MeterBlinkGraph',
  data: () => ({
    headers: [
      {
        text: "Meter #",
        value: "meter_number"
      },
      {
        text: "Time Checked",
        value: "time_checked"
      },
      {
        text: "Substation",
        value: "substation"
      },
      {
        text: "Feeder",
        value: "feeder"
      },
      {
        text: "Blink Count",
        value: "blink_count"
      },
      {
        text: "Total Blinks",
        value: "total_blinks"
      },
    ],
    panel: [0,1],
    picker: new Date().toISOString().substr(0, 10),
    menu: false,
    blinkChart: {}
  }),
  props: {
    meter_number: {
      type: String,
      required: true
    },
    blink_records: {
      type: Array,
      required: true
    }
  },
  watch:{
    blinks_records: {
      handler: function(newVal){
        this.createChart();

      },
      deep: true
    }
  },
  computed:{
  },
  methods:{
    createChart() {
      if (!Array.isArray(this.blink_records) || !this.blink_records.length){
        console.log("blink_records error")
        return
      }
      const ctx = document.getElementById("blink-chart");
      let ys = this.blink_records.map(x => x.blink_count);
      let xs = this.blink_records.map(x => x.time_checked);
      let trace = {
        type: "scatter",
        mode: "lines",
        name: 'Blink Count',
        x: xs,
        y: ys,
        line: {color: '#17BECF'}};
      let layout = {
        title: "Blink Count -- Meter #: " + this.meter_number,
        yaxis: {
          showgrid: true,
          zeroline: true,
          showline: true,
          mirror: 'ticks',
          gridcolor: '#bdbdbd',
          gridwidth: 1,
          zerolinecolor: '#969696',
          zerolinewidth: 2,
          linecolor: '#636363',
          linewidth: 4,
          rangemode: 'tozero',
          title: {
            text: "Blink Count (#)"
          }
        }
      };
      let data = [trace];
      this.blinkChart = Plotly.newPlot(ctx,
          data,
          layout);
    },
  }
  ,
  mounted() {
    console.log("Meter Blink Graph")
    this.createChart();
  },

}
</script>
