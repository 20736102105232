<template>
  <div>
    <v-navigation-drawer
        right
        absolute
        permanent
        :width="150">
      <v-list dense class="mb-0 pb-0 mt-0 mb-0">

        <v-list-item class="mt-0 pt-0 mb-0 pt-0">
            <v-checkbox v-model="sourceLayerVisibility" @change="setLayerVisibilities">
            </v-checkbox>Substations
        </v-list-item>

        <v-list-item class="mt-0 pt-0 mb-0 pt-0">
            <v-checkbox v-model="recloserLayerVisibility" @change="setLayerVisibilities">
            </v-checkbox>Reclosers
        </v-list-item>

        <v-list-item class="mt-0 pt-0 mb-0 pt-0">
            <v-checkbox v-model="fuseLayerVisibility" @change="setLayerVisibilities">
            </v-checkbox>Fuses
        </v-list-item>

        <v-list-item class="mt-0 pt-0 mb-0 pt-0">
          <v-checkbox v-model="regulatorLayerVisibility" @change="setLayerVisibilities">
          </v-checkbox>Regulators
        </v-list-item>

        <v-list-item class="mt-0 pt-0 mb-0 pt-0">
          <v-checkbox v-model="consumerLayerVisibility" @change="setLayerVisibilities">
          </v-checkbox>Consumers
        </v-list-item>

        <v-list-item class="mt-0 pt-0 mb-0 pt-0">
          <v-checkbox v-model="capacitorLayerVisibility" @change="setLayerVisibilities">
          </v-checkbox>Capacitors
        </v-list-item>

        <v-list-item  v-if="validBlinkData()" class="mt-0 pt-0 mb-0 pt-0">
          <v-checkbox v-model="blinkLayerVisibility" @change="setLayerVisibilities">
          </v-checkbox>Blinks
        </v-list-item>

        <v-list-item v-if="validVoltageData()" class="mt-0 pt-0 mb-0 pt-0">
          <v-checkbox v-model="voltageLayerVisibility" @change="setLayerVisibilities">
          </v-checkbox>Voltages
        </v-list-item>

        <v-list-item v-if="validNoreadData()" class="mt-0 pt-0 mb-0 pt-0">
          <v-checkbox v-model="noreadLayerVisibility" @change="setLayerVisibilities">
          </v-checkbox>No Reads
        </v-list-item>

        <v-list-item>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <div id="map" style='width: 1440px; height: 1100px;'></div>
    {{mouseClickLngLat}}
<!--    <v-btn v-on:click="changeColor">Change Color</v-btn>-->
  </div>
</template>

<script>
import axios from "axios";
import mapboxgl from 'mapbox-gl';
export default {
  name: 'MapboxTest',
  data: () => ({
    mouseClickLngLat: "",
    recloserLayerVisibility: false,
    fuseLayerVisibility: false,
    regulatorLayerVisibility: false,
    consumerLayerVisibility: false,
    sourceLayerVisibility: true,
    capacitorLayerVisibility: false,
    blinkLayerVisibility: true,
    voltageLayerVisibility: true,
    noreadLayerVisibility: true,
    customColor: "#ff0000",
    capacitors: [],
    milsoftGeoJson: {},
    conductorGeoJson: {},
    sourceGeoJson: {},
    sourceLayer: {},
    conductorLayer: {},
    blinkLayer: {},
    milsoftMap: {}
  }),
  computed:{
  },
  props:{
    blinks: {
      type: Object,
      required: false
    },
    highVoltages: {
      type: Object,
      required: false
    },
    lowVoltages: {
      type: Object,
      required: false
    },
    noreads: {
      type: Object,
      required: false
    },

  },
  watch: {
    blinks: function (newVal, oldVal) { // watch it

      let blinkSource = this.milsoftMap.getSource('blinks')

      if(blinkSource === undefined) {
        this.milsoftMap.addSource('blinks', {
          type: 'geojson',
          data: newVal
        });

        this.milsoftMap.addLayer({
          'id': 'blinks',
          'type': 'symbol',
          'source': 'blinks',
          'layout': {
            'icon-image': 'orange-consumer-marker',
            'text-field': ['get', 'name'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold'
            ],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          },
          'paint': {
            'text-color': '#ff6f00',
          }
        });

        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });
        var popup2 = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        let that = this;
        this.milsoftMap.on('mouseenter', 'blinks', function (e) {
          // Change the cursor style as a UI indicator.
          that.milsoftMap.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.name + "<br>blink count: " + e.features[0].properties.blink_count;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });

        this.milsoftMap.on('mouseleave', 'blinks', function () {
          that.milsoftMap.getCanvas().style.cursor = '';
          popup.remove();
        });

        this.milsoftMap.on('click', 'blinks', function (e) {
          let coordinates = e.features[0].geometry.coordinates.slice();
          let description = "<a href='#/meter/"+e.features[0].properties.meter_number + "'>" +e.features[0].properties.meter_number + "</a>"
          popup2.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });
      }
      else{
        blinkSource.setData(newVal);
      }
    },
    highVoltages: function (newVal, oldVal) { // watch it

      let highVoltageSource = this.milsoftMap.getSource('highVoltages')

      if(highVoltageSource === undefined) {
        this.milsoftMap.addSource('highVoltages', {
          type: 'geojson',
          data: newVal
        });

        // Add an overcurrent symbol layer
        this.milsoftMap.addLayer({
          'id': 'highVoltages',
          'type': 'symbol',
          'source': 'highVoltages',
          'layout': {
            'icon-image': 'red-consumer-marker',
            'text-field': ['get', 'name'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold'
            ],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          },
          'paint': {
            'text-color': '#ff0000',
          }
        });

        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });
        var popup2 = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        let that = this;
        this.milsoftMap.on('mouseenter', 'highVoltages', function (e) {
          // Change the cursor style as a UI indicator.
          that.milsoftMap.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.name +
              "<br> voltage_a : " + e.features[0].properties.voltage_a +
              "<br> voltage_b : " + e.features[0].properties.voltage_b +
              "<br> voltage_c : " + e.features[0].properties.voltage_c


          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });

        this.milsoftMap.on('mouseleave', 'highVoltages', function () {
          that.milsoftMap.getCanvas().style.cursor = '';
          popup.remove();
        });

        this.milsoftMap.on('click', 'highVoltages', function (e) {
          let coordinates = e.features[0].geometry.coordinates.slice();
          let description = "<a href='#/meter/"+e.features[0].properties.meter_number + "'>" +e.features[0].properties.meter_number + "</a>"
          popup2.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });
      }
      else{
        highVoltageSource.setData(newVal);
      }
    },
    lowVoltages: function (newVal, oldVal) { // watch it

      let lowVoltageSource = this.milsoftMap.getSource('lowVoltages')

      if(lowVoltageSource === undefined) {
        this.milsoftMap.addSource('lowVoltages', {
          type: 'geojson',
          data: newVal
        });

        // Add an overcurrent symbol layer
        this.milsoftMap.addLayer({
          'id': 'lowVoltages',
          'type': 'symbol',
          'source': 'lowVoltages',
          'layout': {
            'icon-image': 'blue-consumer-marker',
            'text-field': ['get', 'name'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold'
            ],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          },
          'paint': {
            'text-color': '#0000ff',
          }
        });

        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });
        var popup2 = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        let that = this;
        this.milsoftMap.on('mouseenter', 'lowVoltages', function (e) {
          // Change the cursor style as a UI indicator.
          that.milsoftMap.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.name +
              "<br> voltage_a : " + e.features[0].properties.voltage_a +
              "<br> voltage_b : " + e.features[0].properties.voltage_b +
              "<br> voltage_c : " + e.features[0].properties.voltage_c


          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });

        this.milsoftMap.on('mouseleave', 'lowVoltages', function () {
          that.milsoftMap.getCanvas().style.cursor = '';
          popup.remove();
        });

        this.milsoftMap.on('click', 'lowVoltages', function (e) {
          let coordinates = e.features[0].geometry.coordinates.slice();
          let description = "<a href='#/meter/"+e.features[0].properties.meter_number + "'>" +e.features[0].properties.meter_number + "</a>"
          popup2.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });
      }
      else{
        lowVoltageSource.setData(newVal);
      }
    },
    noreads: function (newVal, oldVal) { // watch it

      let noreadSource = this.milsoftMap.getSource('noreads')

      if(noreadSource === undefined) {
        this.milsoftMap.addSource('noreads', {
          type: 'geojson',
          data: newVal
        });

        this.milsoftMap.addLayer({
          'id': 'noreads',
          'type': 'symbol',
          'source': 'noreads',
          'layout': {
            'icon-image': 'cyan-consumer-marker',
            'text-field': ['get', 'name'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold'
            ],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          },
          'paint': {
            'text-color': '#10c9bb',
          }
        });

        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });
        var popup2 = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        let that = this;
        this.milsoftMap.on('mouseenter', 'noreads', function (e) {
          // Change the cursor style as a UI indicator.
          that.milsoftMap.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.name +
              "<br> Class : " + e.features[0].properties.rate_class +
              "<br> Days : " + e.features[0].properties.no_read_days


          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });

        this.milsoftMap.on('mouseleave', 'noreads', function () {
          that.milsoftMap.getCanvas().style.cursor = '';
          popup.remove();
        });

        this.milsoftMap.on('click', 'noreads', function (e) {
          let coordinates = e.features[0].geometry.coordinates.slice();
          let description = "<a href='#/meter/"+e.features[0].properties.meter_number + "'>" +e.features[0].properties.meter_number + "</a>"
          popup2.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
        });
      }
      else{
        noreadSource.setData(newVal);
      }
    }
  },
  methods:{
    validBlinkData(){
      return !(Object.keys(this.blinks).length === 0 && this.blinks.constructor === Object)
    },
    validVoltageData(){
      return !(Object.keys(this.highVoltages).length === 0 && this.highVoltages.constructor === Object)
    },
    validNoreadData(){
      return !(Object.keys(this.noreads).length === 0 && this.noreads.constructor === Object)
    },

    getMilsoft(){
      let that = this
      axios.get(`${this.$store.state.host}/api/v1.0/milsoft/geojson`, {params:{refresh_cache: false}})
          .then(function (response) {
            let milsoftGeoJson = response.data.milsoftGeoJson;
            console.log(milsoftGeoJson);
            let conductorGeoJson = JSON.parse(milsoftGeoJson.conductors);
            let sourceGeoJson = JSON.parse(milsoftGeoJson.sources);
            let overcurrentDeviceGeoJson = JSON.parse(milsoftGeoJson.reclosers);
            let fuseGeoJson = JSON.parse(milsoftGeoJson.fuses);
            let regulatorGeoJson = JSON.parse(milsoftGeoJson.regulators);
            let consumerGeoJson = JSON.parse(milsoftGeoJson.consumers);
            let capacitorGeoJson = JSON.parse(milsoftGeoJson.capacitors)

            let substationURL = require("@/assets/substation.png")
            let overcurrentDeviceURL = require("@/assets/recloser.png")
            let fuseURL = require("@/assets/fuse.png")
            let regulatorURL = require("@/assets/regulator.png")
            let consumerURL = require("@/assets/consumer.png")
            let capacitorURL = require("@/assets/capacitor.png")
            let redConsumerURL = require("@/assets/red_consumer.png")
            let blueConsumerURL = require("@/assets/blue_consumer.png")
            let orangeConsumerURL = require("@/assets/orange_consumer.png")
            let cyanConsumerURL = require("@/assets/cyan_consumer.png")
            const images =[
              {imageUrl: substationURL, id: 'substation-marker'},
              {imageUrl: overcurrentDeviceURL, id: 'recloser-marker'},
              {imageUrl: fuseURL, id: 'fuse-marker'},
              {imageUrl: regulatorURL, id: 'regulator-marker'},
              {imageUrl: consumerURL, id: 'consumer-marker'},
              {imageUrl: capacitorURL, id: 'capacitor-marker'},
              {imageUrl: redConsumerURL, id: 'red-consumer-marker'},
              {imageUrl: blueConsumerURL, id: 'blue-consumer-marker'},
              {imageUrl: orangeConsumerURL, id: 'orange-consumer-marker'},
              {imageUrl: cyanConsumerURL, id: 'cyan-consumer-marker'}
            ]

            Promise.all(
                images.map(img => new Promise((resolve, reject) => {
                  that.milsoftMap.loadImage(img.imageUrl, function (error, res) {
                    that.milsoftMap.addImage(img.id, res)
                    resolve();
                  })
                }))
            ).then( () => {
              that.milsoftMap.addSource('conductors', {
                type: 'geojson',
                data: conductorGeoJson
              });
              that.milsoftMap.addLayer({
                id: "conductors",
                type: "line",
                source: "conductors"
              });

              that.milsoftMap.addSource('overcurrentDevices', {
                type: 'geojson',
                data: overcurrentDeviceGeoJson
              });

              // Add an overcurrent symbol layer
              that.milsoftMap.addLayer({
                'id': 'overcurrentDevices',
                'type': 'symbol',
                'source': 'overcurrentDevices',
                'layout': {
                  'icon-image': 'recloser-marker',
                  'text-field': ['get', 'name'],
                  'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                  ],
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'text-offset': [0, 1.25],
                  'text-anchor': 'top'
                }
              });

              that.milsoftMap.addSource('fuses', {
                type: 'geojson',
                data: fuseGeoJson
              });

              // Add a fuse symbol layer
              that.milsoftMap.addLayer({
                'id': 'fuses',
                'type': 'symbol',
                'source': 'fuses',
                'layout': {
                  'icon-image': 'fuse-marker',
                  'text-field': ['get', 'name'],
                  'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                  ],
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'text-offset': [0, 1.25],
                  'text-anchor': 'top'
                }
              });

              that.milsoftMap.addSource('sources', {
                type: 'geojson',
                data: sourceGeoJson
              });

              // Add a substation symbol layer
              that.milsoftMap.addLayer({
                'id': 'sources',
                'type': 'symbol',
                'source': 'sources',
                'layout': {
                  'icon-image': 'substation-marker',
                  'text-field': ['get', 'name'],
                  'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                  ],
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'text-offset': [0, 1.25],
                  'text-anchor': 'top'
                }
              });

              that.milsoftMap.addSource('regulators', {
                type: 'geojson',
                data: regulatorGeoJson
              });

              // Add a substation symbol layer
              that.milsoftMap.addLayer({
                'id': 'regulators',
                'type': 'symbol',
                'source': 'regulators',
                'layout': {
                  'icon-image': 'regulator-marker',
                  'text-field': ['get', 'name'],
                  'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                  ],
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'text-offset': [0, 1.25],
                  'text-anchor': 'top'
                }
              });

              that.milsoftMap.addSource('consumers', {
                type: 'geojson',
                data: consumerGeoJson
              });

              // Add a substation symbol layer
              that.milsoftMap.addLayer({
                'id': 'consumers',
                'type': 'symbol',
                'source': 'consumers',
                'layout': {
                  'icon-image': 'consumer-marker',
                  'text-field': ['get', 'name'],
                  'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                  ],
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'text-offset': [0, 1.25],
                  'text-anchor': 'top'
                }
              });

              that.milsoftMap.addSource('capacitors', {
                type: 'geojson',
                data: capacitorGeoJson
              });

              // Add a substation symbol layer
              that.milsoftMap.addLayer({
                'id': 'capacitors',
                'type': 'symbol',
                'source': 'capacitors',
                'layout': {
                  'icon-image': 'capacitor-marker',
                  'text-field': ['get', 'name'],
                  'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                  ],
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'text-offset': [0, 1.25],
                  'text-anchor': 'top'
                }
              });

              that.setLayerVisibilities();

              that.milsoftMap.on('click', function (e){
                let lngText = e.lngLat.lat.toString()
                let latText = e.lngLat.lng.toString()
                that.mouseClickLngLat = lngText+","+latText;
                //navigator.clipboard.writeText(latText+","+lngText);
                console.log(JSON.stringify(e.lngLat.wrap()))
              })

              // Create a popup, but don't add it to the map yet.
              var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
              });

              that.milsoftMap.on('mouseenter', 'conductors', function (e) {
                // Change the cursor style as a UI indicator.
                that.milsoftMap.getCanvas().style.cursor = 'pointer';

                var coordinates = e.features[0].geometry.coordinates[1].slice();
                var description = e.features[0].properties.name;

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                popup.setLngLat(coordinates).setHTML(description).addTo(that.milsoftMap);
              });

              that.milsoftMap.on('mouseleave', 'conductors', function () {
                that.milsoftMap.getCanvas().style.cursor = '';
                popup.remove();
              });
            })})
          .catch(function (error) {
            console.log(error);
          });
    },
    changeColor(){
      //this.conductorLayer.setStyle({color: "#00ff0f"})
      console.log("pressed")
    },
    setLayerVisibilities(){
      if(this.recloserLayerVisibility){
        this.milsoftMap.setLayoutProperty('overcurrentDevices', 'visibility', 'visible');
      }
      else{
        this.milsoftMap.setLayoutProperty('overcurrentDevices', 'visibility', 'none');
      }

      if(this.fuseLayerVisibility){
        this.milsoftMap.setLayoutProperty('fuses', 'visibility', 'visible');
      }
      else{
        this.milsoftMap.setLayoutProperty('fuses', 'visibility', 'none');
      }

      if(this.sourceLayerVisibility){
        this.milsoftMap.setLayoutProperty('sources', 'visibility', 'visible');
      }
      else{
        this.milsoftMap.setLayoutProperty('sources', 'visibility', 'none');
      }

      if(this.regulatorLayerVisibility){
        this.milsoftMap.setLayoutProperty('regulators', 'visibility', 'visible');
      }
      else{
        this.milsoftMap.setLayoutProperty('regulators', 'visibility', 'none');
      }

      if(this.consumerLayerVisibility){
        this.milsoftMap.setLayoutProperty('consumers', 'visibility', 'visible');
      }
      else{
        this.milsoftMap.setLayoutProperty('consumers', 'visibility', 'none');
      }

      if(this.capacitorLayerVisibility){
        this.milsoftMap.setLayoutProperty('capacitors', 'visibility', 'visible');
      }
      else{
        this.milsoftMap.setLayoutProperty('capacitors', 'visibility', 'none');
      }

      if(this.validBlinkData()) {
        if (this.blinkLayerVisibility) {
          this.milsoftMap.setLayoutProperty('blinks', 'visibility', 'visible');
        } else {
          this.milsoftMap.setLayoutProperty('blinks', 'visibility', 'none');
        }
      }
      if(this.validVoltageData()) {
        if (this.voltageLayerVisibility) {
          this.milsoftMap.setLayoutProperty('highVoltages', 'visibility', 'visible');
          this.milsoftMap.setLayoutProperty('lowVoltages', 'visibility', 'visible');
        } else {
          this.milsoftMap.setLayoutProperty('highVoltages', 'visibility', 'none');
          this.milsoftMap.setLayoutProperty('lowVoltages', 'visibility', 'none');
        }
      }
      if(this.validNoreadData()) {
        if (this.noreadLayerVisibility) {
          this.milsoftMap.setLayoutProperty('noreads', 'visibility', 'visible');
        } else {
          this.milsoftMap.setLayoutProperty('noreads', 'visibility', 'none');
        }
      }

    }
  },
  mounted() {
    var that = this;
    console.log("Mapbox Test")
    mapboxgl.accessToken = 'pk.eyJ1IjoiY29zbTAxNTYiLCJhIjoiY2s2NnljanZqMXF4ZzNrcW9sdTIyZXIxaSJ9.8hzDkBwyf1nPzeBWRwW0rw';
    this.milsoftMap = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: [-101.6, 34.53],
      zoom: 9.7});


    this.getMilsoft();
  },

}
</script>
<style>
/* global styles */

#mapid { height: 680px; }
.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
</style>