<template>
<!--  style="min-width: 1200px; min-height: 700px"-->
    <div id="histogram-chart" style="min-width: 500px; min-height: 400px">
    </div>
</template>

<script>
  import Plotly from "plotly.js-dist";
  export default {
    name: 'MeterVoltageHistogram',
    data: () => ({
      meterChart: {}
    }),
    props: {
      records: {
        type: Array,
        required: true
      },
    },
    watch: {
      records(){
        console.log("records changed");
        this.createChart();
      }
    },
    computed:{
    },
    methods:{
      createChart() {
        console.log("Creating Meter Histogram")
        const ctx = document.getElementById("histogram-chart");
        let ys_a = this.records.map(x => x.voltage);
        //let ys_a = [1,2,3,4,5,6];
        let ys_b = this.records.map(x => x.voltage_b);
        let trace_a = {
          type: "histogram",
          x: ys_a}
        let data = [trace_a];
        this.meterChart = Plotly.newPlot(ctx, data);
      },
    },
    mounted() {
      console.log("Meter Histogram")
      this.$nextTick(() => {
        this.createChart();
      })

    },
  }
</script>
