<template>
  <div>
    <v-alert type="error" v-if="dataNotFound">
      No voltage data found for {{oldPicker}}
    </v-alert>
  <v-col cols="12" sm="6" md="4" style="z-index: 9000">
    <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
                v-model="picker"
                label="Voltage Report Date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="picker" @input="menu = false"></v-date-picker>
    </v-menu>
  </v-col>

    <meter-voltage-map style="z-index: 1" :picker="picker"></meter-voltage-map>
    <v-row>
      <v-expansion-panels
              v-model="panel"
              multiple
      >
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        </v-col>

        <v-expansion-panel>
          <v-expansion-panel-header>Low Voltages</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                    :headers="headers"
                    :items="lowMeters"
                    :items-per-page="25"
                    class="elevation-1"
            >
              <template slot="item.meter_number" slot-scope="props">
                <!-- <router-link to="/foo" tag="tr"> -->
                <router-link :to="{name: 'meterDetail', params: {meter_number: props.item.meter_number}}" tag="a">
                  {{ props.item.meter_number }}
                </router-link>
                <!-- </router-link> -->
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>High Voltages</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                    :headers="headers"
                    :items="highMeters"
                    :items-per-page="25"
                    class="elevation-1"
            >
              <template slot="item.meter_number" slot-scope="props">
                <!-- <router-link to="/foo" tag="tr"> -->
                <router-link :to="{name: 'meterDetail', params: {meter_number: props.item.meter_number}}" tag="a">
                  {{ props.item.meter_number }}</router-link>
                <!-- </router-link> -->
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
  import axios from "axios";
  import MeterVoltageHistogram from "../components/MeterVoltageHistogram";
  import MeterVoltageMap from "../components/MeterVoltageMap";

  export default {
    name: 'MeterVoltageOverview',
    data: () => ({
      dataNotFound: false,
      oldPicker: "",
      headers: [
        {
          text: "Meter #",
          value: "meter_number"
        },
        {
          text: "Map Location",
          value: "transformer_grid_number"
        },
        {
          text: "Substation",
          value: "substation"
        },
        {
          text: "Feeder",
          value: "feeder"
        },
        {
          text: "Voltage",
          value: "voltage"
        },
        {
          text: "% of Nominal",
          value: "voltage_percent_of_nominal"
        },
        {
          text: "A-Phase Voltage",
          value: "voltage_a"
        },
        // {
        //   text: "A % of Nominal",
        //   value: "voltage_a_percent_of_nominal"
        // },
        {
          text: "B-Phase Voltage",
          value: "voltage_b"
        },
        // {
        //   text: "B % of Nominal",
        //   value: "voltage_b_percent_of_nominal"
        // },
        {
          text: "C-Phase Voltage",
          value: "voltage_c"
        },
        // {
        //   text: "C % of Nominal",
        //   value: "voltage_c_percent_of_nominal"
        // }

      ],
      records: [],
      lowMeters: [],
      highMeters:[],
      panel: [0,1],
      picker: "",
      menu: false
    }),
    watch: {
      picker(val){
        this.getMeters(val);
      }
    },
    computed:{
    },
    methods:{
      getMeters(meterDate){
        let that = this
        axios.get(`${this.$store.state.host}/api/v1.0/meter_voltages/${meterDate}`)
                .then(function (response) {
                  console.log(response);
                  that.records = response.data.records;
                  that.lowMeters = response.data.low_meters;
                  that.highMeters = response.data.high_meters;
                  that.dataNotFound = false;
                  that.oldPicker = that.picker;
                  that.$forceUpdate();
                })
                .catch(function (error) {
                  console.log(error);
                  that.dataNotFound = true;
                  that.oldPicker = that.picker;
                });
      }
    },
    mounted() {
      console.log("Meters Voltage Overview")
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.picker = today.toISOString().substr(0, 10)
      this.oldPicker = this.picker;
      this.getMeters(this.picker)
    },
    components: {
      MeterVoltageHistogram: MeterVoltageHistogram,
      MeterVoltageMap: MeterVoltageMap
    }
  }
</script>
