<template>
  <div>
    <v-row>
    <v-alert type="error" v-if="voltageDataNotFound" dismissible dense>
      No voltage data found for {{picker}}
    </v-alert>
    <v-alert type="error" v-if="blinkDataNotFound" dismissible dense>
      No blink data found for {{picker}}
    </v-alert>
    <v-alert type="error" v-if="noreadDataNotFound" dismissible dense>
      No noread data found for {{picker}}
    </v-alert>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" style="z-index: 9000">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="picker"
                label="Blink Report Date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="picker" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <mapbox-test :blinks="blinks" :low-voltages="lowVoltages" :high-voltages="highVoltages" :noreads="noreads"></mapbox-test>
    </v-row>

  </div>
</template>

<script>
import chroma from "chroma-js"
import axios from "axios";
import MapboxTest from "@/views/MapboxTest";
export default {
  name: 'MeterBlinkMapbox',
  data: () => ({
    voltageDataNotFound: false,
    blinkDataNotFound: false,
    noreadDataNotFound: false,
    picker: new Date().toISOString().substr(0, 10),
    menu: false,
    blinks: {},
    lowVoltages: {},
    highVoltages: {},
    noreads: {}
  }),
  watch: {
    picker(val){
      this.getMeters(val);
    }
  },
  computed:{
  },

  methods:{
    getCapacitors(){
    },
    getMeters(meterDate){
      let that = this
      that.blinkDataNotFound = false;
      that.voltageDataNotFound = false;
      that.noreadDataNotFound = false;
      axios.get(`${this.$store.state.host}/api/v1.0/meters/blinks/${meterDate}`)
          .then(function (response) {
            that.records = response.data.records;
            that.records_geo_json = response.data.geo_json;
            that.blinks = that.records_geo_json;

            that.blinkDataNotFound = false;
          })
          .catch(function (error) {
            console.log(error);
            that.blinkDataNotFound = true;
          });

      axios.get(`${this.$store.state.host}/api/v1.0/meter_voltages/${meterDate}`)
          .then(function (response) {
            that.high_geojson = response.data.high_geojson;
            that.low_geojson = response.data.low_geojson;
            that.highVoltages = that.high_geojson;
            that.lowVoltages = that.low_geojson;
            that.voltageDataNotFound = false;
          })
          .catch(function (error) {
            console.log(error);
            that.voltageDataNotFound = true;
          });
      axios.get(`${this.$store.state.host}/api/v1.0/meters/noreads/${meterDate}`)
          .then(function (response) {
            that.noreads_geo_json = response.data.geo_json;
            that.noreads = that.noreads_geo_json;
            that.noreadDataNotFound = false;
          })
          .catch(function (error) {
            console.log(error);
            that.noreadDataNotFound = true;
          });
    }
  }
  ,
  mounted() {
    console.log("Meter Blink Mapbox")
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    this.picker = today.toISOString().substr(0, 10)
    this.getMeters(this.picker)

  },
  components: {
    MapboxTest: MapboxTest,
  }

}
</script>
<style>
/* global styles */

</style>