<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="report"
        :items-per-page="50"
        sort-by="name"
        :sort-desc=false
        class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'DailyRegulatorReport',
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Tap Time",
        value: "tap_datetime"
      },
      {
        text: "Daily Taps",
        value: "sliding_24h_operations"
      },
      {
        text: "Monthly Taps To Date",
        value: "taps_month_to_date"
      },
      {
        text: "Yearly Taps to Date",
        value: "year_to_date_operations"
      },
      {
        text: "Total Taps",
        value: "total_taps"
      },
      {
        text: "Current Tap Position",
        value: "present_tap_position"
      }
    ],
  }),
  props: {
    report: {
      type: Array,
      required: true
    },
  },
  computed:{
  },
  methods:{

  },
  mounted() {
  },

}
</script>
