<template>
  <div class="home">
    <v-row>
      <v-card min-width="400">
        <v-card-title>Meter Billing Upload</v-card-title>
      <v-file-input
              v-model="files"
              accept=".csv"
              color="deep-purple accent-4"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
          >
            {{ text }}
          </v-chip>

          <span
                  v-else-if="index === 2"
                  class="overline grey--text text--darken-3 mx-2"
          >
        +{{ files.length - 2 }} File(s)
      </span>
        </template>
      </v-file-input>
      <v-btn @click="submitFiles">
        Submit Files
      </v-btn>
      </v-card>
    </v-row>
    <v-row class="pt-6">
      <v-card min-width="400">
        <v-card-title>Meter Blink Upload</v-card-title>
        <v-file-input
                v-model="blinkFiles"
                accept=".csv"
                color="deep-purple accent-4"
                counter
                label="File input"
                multiple
                placeholder="Select your blink files"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
            >
              {{ text }}
            </v-chip>

            <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
            >
        +{{ blinkFiles.length - 2 }} File(s)
      </span>
          </template>
        </v-file-input>
        <v-btn @click="submitBlinkFiles">
          Submit Files
        </v-btn>
      </v-card>
    </v-row>
    <v-row class="pt-6">
      <v-card min-width="400">
        <v-card-title>Meter Voltage Upload</v-card-title>
        <v-file-input
                v-model="voltageFiles"
                accept=".csv"
                color="deep-purple accent-4"
                counter
                label="File input"
                multiple
                placeholder="Select your voltage files"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
            >
              {{ text }}
            </v-chip>

            <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
            >
        +{{ voltageFiles.length - 2 }} File(s)
      </span>
          </template>
        </v-file-input>
        <v-btn @click="submitVoltageFiles">
          Submit Files
        </v-btn>
      </v-card>
    </v-row>
    <v-row class="pt-6">
      <v-card min-width="400">
        <v-card-title>Milsoft Model Upload</v-card-title>
        <v-file-input
                v-model="stdFiles"
                accept=".std"
                color="deep-purple accent-4"
                counter
                label="File input"
                multiple
                placeholder="Select your model files"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
            >
              {{ text }}
            </v-chip>

            <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
            >
        +{{ stdFiles.length - 2 }} File(s)
      </span>
          </template>
        </v-file-input>
        <v-btn @click="submitModelFiles">
          Submit Files
        </v-btn>
      </v-card>
    </v-row>
  </div>
</template>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import {mapActions} from "vuex";
  import axios from "axios";

  export default {
    name: 'meterUploads',
    data: () => ({
      files: [],
      blinkFiles: [],
      stdFiles: [],
      voltageFiles: []
    }),
    methods: {
      submitFiles(){
        /*
          Initialize the form data
        */
        console.log(this.files)
        let formData = new FormData();
        /*
          Iterate over any file sent over appending the files
          to the form data.
        */
        for( let i = 0; i < this.files.length; i++ ){
          let file = this.files[i];
          formData.append("files", file, file.name);
        }
        /*
          Make the request to the POST /multiple-files URL
        */
        axios.post( `${this.$store.state.host}/api/v1.0/meter_billings/`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
        ).then(function(){
          console.log('SUCCESS!!');
        })
                .catch(function(){
                  console.log('FAILURE!!');
                });
      },
      submitBlinkFiles(){
        let blinkFormData = new FormData();
        for( let i = 0; i < this.blinkFiles.length; i++ ){
          let file = this.blinkFiles[i];
          blinkFormData.append("files", file, file.name);
        }
        axios.post( `${this.$store.state.host}/api/v1.0/meter_blinks/`,
                blinkFormData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
        ).then(function(){
          console.log('SUCCESS!!');
        })
                .catch(function(){
                  console.log('FAILURE!!');
                });
      },
      submitModelFiles(){
        let modelFormData = new FormData();
        for( let i = 0; i < this.stdFiles.length; i++ ){
          let file = this.stdFiles[i];
          modelFormData.append("files", file, file.name);
        }
        axios.post( `${this.$store.state.host}/api/v1.0/milsoft/std_model`,
                modelFormData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
        ).then(function(){
          console.log('SUCCESS!!');
        })
                .catch(function(){
                  console.log('FAILURE!!');
                });
      },
      submitVoltageFiles(){
        let voltageFormData = new FormData();
        for( let i = 0; i < this.voltageFiles.length; i++ ){
          let file = this.voltageFiles[i];
          voltageFormData.append("files", file, file.name);
        }
        axios.post( `${this.$store.state.host}/api/v1.0/meter_voltages/`,
                voltageFormData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
        ).then(function(){
          console.log('SUCCESS!!');
        })
                .catch(function(){
                  console.log('FAILURE!!');
                });
      },
      ...mapActions([
        'loadTreeView'
      ])
    },

    components: {

    }
  }
</script>
