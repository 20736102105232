<template>
  <div>
    <div id="metric-chart" style="min-width: 1200px; min-height: 700px">
      <p>Metric Chart:  {{metricCSVName}}</p>
    </div>
    <v-row>
          <vue-json-to-csv :json-data="metricCSV" :csv-title="metricCSVName">
            <v-btn>
              Download as CSV
            </v-btn>

          </vue-json-to-csv>
    </v-row>
  </div>
</template>

<script>
  import axios from "axios";
  import Plotly from "plotly.js-dist"
  import VueJsonToCsv from 'vue-json-to-csv';

  export default {
    name: 'MetricGraph',
    props: {
      tableName: {
        type: String,
        required: true
      },
      entityId: {
        type: String,
        required: true
      },
      metricName: {
        type: String,
        required: true
      }
    },
    data: () => ({
      metric: {},
      metricChart: {metric_meta: {name: "Title"}},
      metricCSV: [],
      metricCSVName: 's'
    }),
    computed: {},
    methods: {
      getMetric(){
        let that = this;
        axios.get(`${this.$store.state.host}/api/v1.0/survalent_entities/metric/${this.entityId}/${this.metricName}/${this.tableName}`)
                .then(function (response) {
                  that.metric = response.data.metric
                  that.createChart();
                  that.createCSVJson();
                })
                .catch(function (error) {
                  console.log(error);
                });

      },
      createCSVJson(){
        let csv_json = [];
        for (let i = 0; i < this.metric.x.length; i++){
          csv_json.push({'x':this.metric.x[i], 'y':this.metric.y[i]})
        }
        this.metricCSV = csv_json;
        this.metricCSVName = this.metric.metric_meta.table_field_name;

      },
      createChart() {
        console.log("Creating Metric Graph")
        const ctx = document.getElementById("metric-chart");
        let trace = {
          type: "scatter",
          mode: "lines",
          name: 'Max MW',
          x: this.metric.x,
          y: this.metric.y,
          line: {color: '#17BECF'}};
        let layout = {
          title: this.metric.metric_meta.name,
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            mirror: 'ticks',
            gridcolor: '#bdbdbd',
            gridwidth: 1,
            zerolinecolor: '#969696',
            zerolinewidth: 2,
            linecolor: '#636363',
            linewidth: 4,
            rangemode: 'tozero'
          }
        };
        let data = [trace];
        this.metricChart = Plotly.newPlot(ctx,
                data,
                layout);
      },
    },
    mounted() {
      this.getMetric();
      console.log("Metric Graph")
    },
    components:{
      VueJsonToCsv
    },

  }
</script>
